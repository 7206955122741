import React, { useEffect, useState } from 'react'
import Constants from '../../../../../../constants'
import ProviderService from 'services/ProviderService'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { FeedbackError } from 'components/feedback/error'
import { FeedbackSuccess } from 'components/feedback/success'
import { loadEditLoggedProviderData } from 'store/providers'
import './index.scss'
import { Input } from 'components/input'
import { Select } from 'components/select'
import { Button } from 'components/button'
import { useContext } from 'react'
import { UserContext } from 'contexts/user'
import InputV2 from 'components/InputV2'
import { MdEdit } from 'react-icons/md'
import ButtonV3 from 'components/Buttonv3'
import noUserImage from '../../../../../../assets/images/sign-in-bg.png'
import ModalImage from 'react-modal-image'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Dropzone from 'react-dropzone'
import { TbCloudUpload } from 'react-icons/tb'
import IconReact from 'components/icons'
import Utils from 'utils'
import { SelectV2 } from 'components/selectV2'
export const Fornecedor = ({
    editLoggedProviderData,
    loadEditLoggedProviderData,
    setLoading,
}: any) => {
    const [successMessage, setSuccessMessage] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [dragging, setDragging] = useState(false)
    const [imageSrc, setImageSrc] = useState()
    const [pixGateway, setPixGateway] = useState(
        editLoggedProviderData.provider.pix_gateway
    )
    const { user } = useContext(UserContext)
    const MySwal = withReactContent(Swal)

    const save = (data: any) => {
        const provider = {
            ...data,
            city: { id: data.city },
            state: { id: data.uf },
            id: editLoggedProviderData.provider.id,
            storeId: editLoggedProviderData.provider.storeId,
            // website: editLoggedProviderData.provider.website,
            pix_gateway: pixGateway != '' ? pixGateway : null,
        }
        setLoading(true)
        ProviderService.edit(provider, user.id)
            .then((response) => {
                if (response.status === Constants.HTTP_CODE_SUCCESS) {
                    setSuccessMessage(Constants.MSG_PROVIDER_SUCCESS_EDIT)
                } else {
                    setErrorMessage(Constants.MSG_PROVIDER_ERROR_EDIT)
                }
                loadEditLoggedProviderData().then(() => setLoading(false))
            })
            .catch(() => {
                setLoading(false)
                setErrorMessage(Constants.MSG_PROVIDER_ERROR_EDIT)
            })
    }
    const { register, handleSubmit, errors } = useForm()

    async function handleUploadImage(image) {
        image &&
            ProviderService.editImage(
                editLoggedProviderData.provider.id,
                Utils.formatImageToApi(image)
            )
    }

    const uploadImage = (event: any) => {
        const reader = new FileReader()

        reader.onload = (e: any) => {
            const img = new Image()
            img.onload = function () {
                if (img.height > img.width) {
                    MySwal.fire({
                        title: 'Imagem invalida',
                        text: 'Desculpe, apenas imagens do tipo paisagem(foto de lado) são aceitas.',
                        icon: 'error',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok',
                    })
                    return false
                } else if (img.height > 360 || img.width > 640) {
                    MySwal.fire({
                        title: 'Imagem invalida',
                        text: 'Desculpe, a imagem deve ter um limite de 360px de altura e 640px de largura.',
                        icon: 'error',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok',
                    })
                    return false
                } else handleUploadImage(e.target.result)
            }
            img.src = e.target.result
        }
        event.target
            ? reader.readAsDataURL(event.target.files[0])
            : reader.readAsDataURL(event[0])

        //reader.readAsDataURL(event[0])
        //reader.readAsDataURL(event)
    }

    return (
        <div className="form">
            <form onSubmit={handleSubmit(save)}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Dropzone onDrop={uploadImage}>
                        {({ getRootProps, getInputProps }) => (
                            <section style={{ display: 'flex' }}>
                                <div style={{}} {...getRootProps()}>
                                    <div
                                        onDragOver={() => setDragging(true)}
                                        onDragExit={() => setDragging(false)}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                        }}
                                        style={{}}
                                    >
                                        <ModalImage
                                            small={
                                                editLoggedProviderData?.provider
                                                    .cover_file_name
                                                    ? editLoggedProviderData
                                                          .provider
                                                          .cover_file_name
                                                    : require('../../../../../../assets/logocdclaranja.jpeg')
                                            }
                                            large={
                                                editLoggedProviderData?.provider
                                                    .cover_file_name
                                                    ? editLoggedProviderData
                                                          .provider
                                                          .cover_file_name
                                                    : 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'
                                            }
                                            className={
                                                'userImageStyleMyConfigs'
                                            }
                                            alt={`imagem do usuário`}
                                            hideZoom={true}
                                            showRotate={true}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <div
                                            style={{ width: 40 }}
                                            title="Alterar Imagem"
                                        >
                                            <IconReact
                                                type="edit"
                                                color={'gray'}
                                                size={20}
                                                style={{ marginBottom: 7 }}
                                            />
                                            <input
                                                onClick={() => {}}
                                                {...getInputProps()}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>

                {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalImage
                        small={editLoggedProviderData?.provider.cover_file_name
                            ? editLoggedProviderData.provider.cover_file_name : require('../../../../../../assets/logocdclaranja.jpeg')}
                        large={editLoggedProviderData?.provider.cover_file_name
                            ? editLoggedProviderData.provider.cover_file_name : 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                        className={'userImageStyleMyConfigs'}
                        alt={`imagem do usuário`}
                        hideZoom={true}
                        showRotate={true}
                    />

                </div> */}
                <div className="mainProviderInfoContainer">
                    <div className="containerInputItensfornecedor">
                        {errorMessage ||
                            (successMessage && (
                                <div>
                                    {errorMessage && (
                                        <FeedbackError message={errorMessage} />
                                    )}
                                    {successMessage && (
                                        <FeedbackSuccess
                                            message={successMessage}
                                        />
                                    )}
                                    <br />
                                </div>
                            ))}
                        <div style={{ marginBottom: 10 }}>
                            {
                                <Input
                                    infoIconNull={false}
                                    id="cnpj"
                                    name="cnpj"
                                    description="CNPJ"
                                    readOnly={true}
                                    placeholder="CNPJ"
                                    register={register({ required: true })}
                                    defaultValue={
                                        editLoggedProviderData?.provider.cnpj
                                            ? editLoggedProviderData.provider
                                                  .cnpj
                                            : ''
                                    }
                                />
                            }

                            {errors.cnpj && (
                                <FeedbackError
                                    message={Constants.MSG_REQUIRED_FIELD}
                                />
                            )}
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            {
                                <Input
                                    id="socialReason"
                                    name="socialReason"
                                    infoIconNull={false}
                                    readOnly={true}
                                    description="Razão social"
                                    placeholder="Razão social"
                                    register={register({ required: true })}
                                    defaultValue={
                                        editLoggedProviderData?.provider
                                            .socialReason
                                            ? editLoggedProviderData.provider
                                                  .socialReason
                                            : ''
                                    }
                                />
                            }

                            {errors.socialReason && (
                                <FeedbackError
                                    message={Constants.MSG_REQUIRED_FIELD}
                                />
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            <section className="twoInputsSection">
                                <div style={{}}>
                                    <Input
                                        id="name"
                                        name="name"
                                        infoIconNull={false}
                                        description="Nome"
                                        readOnly={true}
                                        placeholder="Nome"
                                        register={register({ required: true })}
                                        defaultValue={
                                            editLoggedProviderData?.provider
                                                .name
                                                ? editLoggedProviderData
                                                      .provider.name
                                                : ''
                                        }
                                    />
                                    {errors.name && (
                                        <FeedbackError
                                            message={
                                                Constants.MSG_REQUIRED_FIELD
                                            }
                                        />
                                    )}
                                </div>
                                <div>
                                    <Input
                                        id="login"
                                        name="login"
                                        infoIconNull={false}
                                        description="Login"
                                        placeholder="Login"
                                        register={register({ required: true })}
                                        defaultValue={
                                            editLoggedProviderData?.provider
                                                .login
                                                ? editLoggedProviderData
                                                      .provider.login
                                                : ''
                                        }
                                    />
                                    {errors.login && (
                                        <FeedbackError
                                            message={
                                                Constants.MSG_REQUIRED_FIELD
                                            }
                                        />
                                    )}
                                </div>
                            </section>

                            <section className="twoInputsSection">
                                <div style={{}}>
                                    <Input
                                        id="contact"
                                        infoIconNull={false}
                                        name="contact"
                                        description="Contato"
                                        placeholder="Contato"
                                        register={register({ required: true })}
                                        defaultValue={
                                            editLoggedProviderData?.provider
                                                .contact
                                                ? editLoggedProviderData
                                                      .provider.contact
                                                : ''
                                        }
                                    />
                                    {errors.contact && (
                                        <FeedbackError
                                            message={
                                                Constants.MSG_REQUIRED_FIELD
                                            }
                                        />
                                    )}
                                </div>
                                <div>
                                    <Input
                                        id="phone"
                                        infoIconNull={false}
                                        name="phone"
                                        placeholder="Telefone"
                                        description="Telefone"
                                        register={register({ required: true })}
                                        defaultValue={
                                            editLoggedProviderData?.provider
                                                .phone
                                                ? editLoggedProviderData
                                                      .provider.phone
                                                : ''
                                        }
                                    />
                                    {errors.phone && (
                                        <FeedbackError
                                            message={
                                                Constants.MSG_REQUIRED_FIELD
                                            }
                                        />
                                    )}
                                </div>
                            </section>
                        </div>

                        <div>
                            <Input
                                id="website"
                                infoIconNull={false}
                                name="website"
                                description="Site"
                                placeholder="Site"
                                register={register({ required: false })}
                                defaultValue={
                                    editLoggedProviderData?.provider.website
                                        ? editLoggedProviderData.provider
                                              .website
                                        : ''
                                }
                            />
                            {errors.website && (
                                <FeedbackError
                                    message={Constants.MSG_REQUIRED_FIELD}
                                />
                            )}
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <SelectV2
                                id="pix_gateway"
                                description="Gateway de Pagamento"
                                onChange={(e: any) =>
                                    setPixGateway(e.target.value)
                                }
                                options={[
                                    {
                                        id: 'adaptabank',
                                        name: 'adaptabank',
                                    },
                                    {
                                        id: 'pixgestor',
                                        name: 'pixgestor',
                                    },
                                    {
                                        id: 'santander',
                                        name: 'santander',
                                    },
                                ]}
                                value={pixGateway}
                                blank_label="Selecione"
                                value_key="id"
                                label_key="name"
                            />
                        </div>
                    </div>
                    <div
                        style={{ margin: 15 }}
                        className="containerInputItensfornecedor"
                    >
                        <section className="twoInputsSection">
                            <div>
                                <Input
                                    id="cep"
                                    infoIconNull={false}
                                    name="cep"
                                    placeholder="Cep"
                                    description="CEP"
                                    readOnly={true}
                                    register={register({ required: true })}
                                    defaultValue={
                                        editLoggedProviderData?.provider.cep
                                            ? editLoggedProviderData.provider
                                                  .cep
                                            : ''
                                    }
                                />
                            </div>
                            <div style={{ width: 190 }}>
                                <Select
                                    id="uf"
                                    name="uf"
                                    description="UF"
                                    label_key="name"
                                    value_key="id"
                                    readOnly={true}
                                    register={register({ required: true })}
                                    defaultValue={
                                        editLoggedProviderData?.provider.state
                                            .id
                                    }
                                    options={editLoggedProviderData?.states}
                                />
                                {errors.uf && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>
                        </section>
                        <div style={{ marginBottom: 10 }}>
                            <Input
                                id="address"
                                infoIconNull={false}
                                name="address"
                                placeholder="Endereço"
                                description="Endereço"
                                readOnly={true}
                                register={register({ required: true })}
                                defaultValue={
                                    editLoggedProviderData?.provider.address
                                        ? editLoggedProviderData.provider
                                              .address
                                        : ''
                                }
                            />
                            {errors.address && (
                                <FeedbackError
                                    message={Constants.MSG_REQUIRED_FIELD}
                                />
                            )}
                        </div>
                        <section className="twoInputsSection">
                            <div style={{}}>
                                <Input
                                    id="neighborhood"
                                    name="neighborhood"
                                    infoIconNull={false}
                                    description="Bairro"
                                    readOnly={true}
                                    placeholder="Bairro"
                                    register={register({ required: true })}
                                    defaultValue={
                                        editLoggedProviderData?.provider
                                            .neighborhood
                                            ? editLoggedProviderData.provider
                                                  .neighborhood
                                            : ''
                                    }
                                />
                                {errors.neighborhood && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>
                            <div>
                                <Input
                                    id="addressComplement"
                                    infoIconNull={false}
                                    name="addressComplement"
                                    readOnly={true}
                                    description="Complemento"
                                    placeholder="Complemento"
                                    register={register({ required: true })}
                                    defaultValue={
                                        editLoggedProviderData?.provider
                                            .addressComplement
                                            ? editLoggedProviderData.provider
                                                  .addressComplement
                                            : ''
                                    }
                                />
                                {errors.addressComplement && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>
                        </section>

                        <section className="twoInputsSection">
                            <div style={{ marginBottom: 10, width: 190 }}>
                                <Select
                                    id="city"
                                    name="city"
                                    label_key="name"
                                    value_key="id"
                                    description="Cidade"
                                    readOnly={true}
                                    defaultValue={
                                        editLoggedProviderData?.provider.city.id
                                    }
                                    register={register({ required: true })}
                                    options={editLoggedProviderData?.cities}
                                />
                                {errors.city && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                <Input
                                    id="minimum_billing"
                                    infoIconNull={false}
                                    name="minimum_billing"
                                    type="number"
                                    description="Faturamento Min."
                                    placeholder="Faturamento Min."
                                    register={register({ required: true })}
                                    defaultValue={
                                        editLoggedProviderData?.provider
                                            .minimum_billing
                                            ? editLoggedProviderData.provider
                                                  .minimum_billing
                                            : ''
                                    }
                                />
                                {errors.minimum_billing && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>
                        </section>
                        <div style={{ marginTop: -9 }}>
                            <Input
                                id="email"
                                infoIconNull={false}
                                name="email"
                                description="E-mail"
                                placeholder="E-mail"
                                register={register({ required: true })}
                                defaultValue={
                                    editLoggedProviderData?.provider.email
                                        ? editLoggedProviderData.provider.email
                                        : ''
                                }
                            />
                            {errors.email && (
                                <FeedbackError
                                    message={Constants.MSG_REQUIRED_FIELD}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        width: '90%',
                        marginTop: 20,
                    }}
                >
                    <br />
                    {
                        //    <Button type="submit" className={'c-btn-save'}>
                        //        <i className="material-icons">save_alt</i>Salvar
                        //    </Button>
                    }
                    <ButtonV3
                        filled
                        type="submit"
                        title="Salvar"
                        onClick={{}}
                    ></ButtonV3>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.user,
    editLoggedProviderData: state.editProvider,
})

const mapDispatchToProps = (dispatch: any) => ({
    loadEditLoggedProviderData: () => dispatch(loadEditLoggedProviderData()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Fornecedor)
