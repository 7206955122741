/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable array-bracket-spacing */
import { Checkbox } from 'components/checkbox'
import { FilterComponent } from 'components/filter'
import Constants from '../../../../constants'
import React, { FC, useContext, useEffect, useState } from 'react'
import { BsSearch, BsFillPersonPlusFill, BsCalendarDay } from 'react-icons/bs'
import { Input } from '../../../../components/input'
import { Select } from '../../../../components/select'
import styles from './index.module.scss'
import { UserContext } from 'contexts/user'
import SalesmanService from 'services/SalesmanService'
import UserService from 'services/UserService'
import { FilterComponentBuyingCompany } from '../filter'
import { Button } from 'components/button'
import { TbUsersPlus, TbUsersGroup } from 'react-icons/tb'

import './index.scss'
import { CheckboxV2 } from 'components/checkboxV2'
import ButtonV3 from 'components/Buttonv3'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import { IoMdTrash } from 'react-icons/io'

export const ClientCompaniesFilterComponent: FC<any> = ({
    onChange,
    states,
    extraActionButtonText,
    onOpenRegisterModal,
    onOpenWeekdayModal,
    registerAmount,
    updateCot,
    clientStatus,
    onCleanColumnSort,
}: any) => {
    const init = {
        statuses: [Constants.STATUS_ACTIVE],
        states: [],
        cnpj: '',
        name: '',
        vendedor: [],
        ramo: [],
        sort: 'l.loj_razao',
    }

    const [params, setParams] = useState<any>(init)
    const { can } = useContext(UserContext)
    const [salesmen, setSalesmen] = useState<any>([])
    const [activitesBranch, setActivitesBranch] = useState([])

    const update = (params: any) => {
        onChange(params)

        if (onCleanColumnSort) {
            onCleanColumnSort()
        }
    }

    const clearFilters = () => {
        setParams(init)

        if (onCleanColumnSort) {
            onCleanColumnSort()
        }
    }

    const loadRamos = async () => {
        try {
            const data = await UserService.ramosDeAtividade()
            setActivitesBranch(data)
        } catch (e) {
            console.error(e)
        }
    }

    const update_status = (event: any, status: boolean) => {
        //const selectedStatus = event.target.value
        const selectedStatus = event
        const filteredStatus = params.statuses
        //if (event.target.checked) {
        if (status) {
            filteredStatus.push(selectedStatus)
        } else {
            filteredStatus.pop(selectedStatus)
        }

        setParams({ ...params, statuses: filteredStatus })
    }
    // const update_status = (event: any) => {
    //     console.log('checke d:::????', event.target.value);
    //
    //     //const selectedStatus = event.target.value
    //     const selectedStatus = event.target.value
    //     const filteredStatus = params.statuses
    //     if (event.target.checked) {
    //         filteredStatus.push(selectedStatus)
    //     } else {
    //         filteredStatus.pop(selectedStatus)
    //     }
    //     setParams({ ...params, statuses: filteredStatus })
    // }

    useEffect(() => {
        loadRamos()
        SalesmanService.getAll().then((data) => setSalesmen(data))
    }, [])

    const handle = (evt: any) => {
        evt.preventDefault()
        update(params)
    }
    function handleStatus(status) {
        // let newParams = { ...params, statuses: [status] }
        // setParams(newParams)
        // update(newParams)
        updateCot(status)
    }

    return (
        <form onSubmit={handle}>
            <div className="filtersContainer">
                {/* {
                    //   <FilterComponentBuyingCompany
                    //       title="Filtrar Empresas"
                    //       filter={() => update(params)}
                    //       clear={clearFilters}
                    //       extraActionButtonText={extraActionButtonText}
                    //       weekday={onOpenWeekdayModal}
                    //       register={onOpenRegisterModal}
                    //       registerAmount={registerAmount}
                    //   >
                } */}
                <div className="filterItem">
                    <Input
                        description="Nome ou CNPJ"
                        placeholder="Nome ou CNPJ"
                        value={params.name}
                        onChange={(e: any) =>
                            setParams({ ...params, name: e.target.value })
                        }
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                if (params.name.trim()) {
                                    update(params)
                                }
                            }
                        }}
                    />
                </div>
                <div className="filterItem">
                    <Select
                        description="Estado"
                        onChange={(e: any) => {
                            const selectedState = states.find(
                                (state) => state.value === e.target.value
                            )
                            setParams({
                                ...params,
                                state: selectedState?.est_id,
                            })
                        }}
                        options={states ? states : []}
                        value={
                            states.find(
                                (state) => state.est_id === params.state
                            )?.value || ''
                        }
                        blank_label="Selecione um Estado"
                        value_key="value"
                        label_key="name"
                    />
                </div>

                {can('READ_ALL', 'CLIENT_COMPANY') && (
                    <div className="filterItem">
                        <Select
                            description="Vendedor"
                            value={params.vendedor}
                            value_key="id"
                            label_key="name"
                            blank_label="Selecione um vendedor"
                            options={salesmen}
                            onChange={(e: any) =>
                                setParams({
                                    ...params,
                                    vendedor: e.target.value,
                                })
                            }
                        ></Select>
                    </div>
                )}
                {/* <div className="filterItem">
                    <Select
                        id="entrega"
                        value={params.delivery_type_id}
                        value_key="value"
                        description="Tipo de entrega"
                        label_key="name"
                        disabled={!can('READ_ALL', 'REQUEST')}
                        blank_label="Tipo de entrega"
                        options={[
                            {
                                name: 'CIF',
                                value: 1,
                            },
                            {
                                name: 'CIF/FOB',
                                value: 2,
                            },
                        ]}
                        onChange={(e: any) =>
                            setParams({
                                ...params,
                                delivery_type_id: e.target.value,
                            })
                        }
                    ></Select>
                </div> */}
                {can('READ_OTHERS', 'CLIENT_COMPANY') && (
                    <div className="filterItem">
                        <Select
                            type="text"
                            id="ramo"
                            name="ramo"
                            value={params.ramo}
                            value_key="id"
                            label_key="name"
                            description="Ramo de atividade"
                            blank_label="Selecione um ramo"
                            options={activitesBranch}
                            onChange={(e: any) =>
                                setParams({ ...params, ramo: e.target.value })
                            }
                        />

                        {/* <CheckBoxCustom
                            value={Constants.STATUS_INACTIVE}
                            change={(e, stats) => update_status(e, stats)}
                            checked={params.statuses?.includes(
                                Constants.STATUS_INACTIVE
                            )}
                            description={'Inativo'}
                        ></CheckBoxCustom> */}
                    </div>
                )}

                {
                    <div className="actionsItemButton">
                        <ButtonV3
                            title="Limpar filtros"
                            onClick={() => clearFilters()}
                            Icon={<IoMdTrash className="iconButtonV3" />}
                        ></ButtonV3>
                    </div>
                }
                {
                    <div className="filterButton">
                        <ButtonV3
                            Icon={
                                <BsSearch
                                    className="iconButtonV3"
                                    color="#fff"
                                />
                            }
                            title="Filtrar"
                            filled
                            onClick={() => update(params)}
                        ></ButtonV3>
                    </div>
                }

                {
                    // <Button
                    //     className="c-btn-outline"
                    //     onClick={() => update(params)}
                    // >
                    //     <BsSearch />
                    //     Filtrar
                    // </Button>
                }
                {/* {
                    < /FilterComponentBuyingCompany>
                } */}
            </div>
        </form>
    )
}
